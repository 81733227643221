/* Versión Escritorio */
@media only screen and (min-width: 768px) {
  .container {
    position: fixed;
    height: calc(30%);
    width: calc(4%);
    top: calc(30%);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #900f1d;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .logo {
    padding-left: calc(25%);
    width: calc(80%);
  }
}

/* Versión Mobile */
@media only screen and (max-width: 768px) {
  .container {
    position: absolute;
    height: 35vh;
    width: 7.5vw;
    top: 32.5vh;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #900f1d;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .logo {
    padding-left: calc(25%);
    width: calc(80%);
  }
}

