/* Versión Escritorio */
@media only screen and (min-width: 768px) {
  .mainContainer {
    display: flex;
    justify-content: space-between;
  }

  .pageLayout {
    display: flex;
    min-width: 100%;
  }

  .myName {
    font-size: 26px;
  }

  .name {
    font-size: 48px;
    font-weight: 900;
  }

  .paragraph {
    font-size: 16px;
    text-align: justify;
  }

  .descriptionContainer {
    width: calc(50%);
    left: calc(20%);
    padding-left: calc(15%);
    padding-top: calc(5.5%);
  }
  .photo {
    display: flex;
    width: 30vw;
    height: 30vw;
    justify-content: right;
    align-items: flex-end;
  }

  .backgroundCircle {
    z-index: 1;
    position: fixed;
    width: 30vw;
  }

  .backgroundCard {
    z-index: 0;
    position: fixed;
    width: 35vw;
  }

  .backgroundPhoto {
    z-index: 2;
    margin-bottom: 25px;
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
    position: fixed;
    width: 35vw;
  }
}

/* Versión Mobile */
@media only screen and (max-width: 768px) {

  .pageLayout{
    display: grid;
    height: 80vh;
    grid-template-rows: 10vh 40vh 20vh;
  }

  .mainContainer{
    display: grid;
    height: 80vh;
    grid-template-rows: 10vh 40vh 20vh;
  }

  .descriptionContainer{
    display: grid;
    height: 80vh;
    grid-template-rows: 10vh 40vh 20vh;
  }

  .myName{
    position: relative;
    padding-top: 1vh;
    grid-row: 1/2;
    grid-column: 1/3;
    font-size: 1.25rem;
    text-align: center;
  }

  .name{
    position: relative;
    top: 3.5vh;
    font-size: 1.5rem;
    grid-row: 1/2;
    grid-column: 1/3;
    font-weight: bolder;
    text-align: center;
  }

  .photo{
    display: flex;
    flex-direction: column;
    grid-row: 2/3;
    align-items: flex-end;
    justify-content: center;
    width: 75vw;
    max-height: 100vw;
  }
  .backgroundPhoto{
    position: absolute;
    width: 75vw;
  }
  .backgroundCircle{
    position: absolute;
    width: 75vw;
  }
  .backgroundCard{
    position: absolute;
    width: 75vw;
  }
  .paragraph{
    grid-row: 3/4;
    padding: 0 1.5rem;
    text-align: justify;
  }

  .experiencia{
    display: flex;
    grid-row: 2/3;
    padding-top: 27.4vh;
  }

  .profesionalismo{
    display: flex;
    grid-row: 2/3;
    padding-top: 14.1vh;
  }

  .compartir{
    display: flex;
    grid-row: 2/3;
    padding-top: 0.8vh;
  }
  
}
