/* Versión Escritorio */
@media only screen and (min-width: 768px) {
  .header {
    width: 95vw;
    height: 20vh;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 7.5vh;
  }

  .logo {
    position: absolute;
    left: 5.25vw;
    top: 0vh;
    height: 20vh;
  }

  .header ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
  }

  .header li {
    padding-left: 5vw;
  }

  .header a {
    text-decoration: none;
    font-size: 1.5vw;
    color: black;
  }

  .header a:hover,
  .header a:active,
  .header a.active {
    color: #900f1d;
  }
}

/* Versión Mobile */
@media only screen and (max-width: 768px) {
  .header {
    width: 100vw;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 1vh;
  }

  .imgContainer {
    display: flex;
    width: 100%;
    height: 10vh;
    align-items: center;
    flex-direction: column;
  }

  .logo {
    height: 15vh;
  }

  .header ul {
    list-style: none;
    display: flex;
    padding: 0;
  }

  .header a {
    text-decoration: none;
    font-size: 4vw;
    color: black;
  }

  .header a:hover,
  .header a:active,
  .header a.active {
    color: #900f1d;
  }

  .navBar{
    padding-top: 3vh;
    width: 100%;
  }
  .navUl{
    display: flex;
    justify-content: space-around;
  }
}
