/* Versión escritorio */
@media only screen and (min-width: 768px) {
  .buttonBackground {
    position: absolute;
    right: -8px;
    top: -8px;
    background-color: #055f80;
    width: 3vw;
    height: 3vw;
    border-radius: 100%;
  }

  .close {
    position: absolute;
    right: -0.6vw;
    top: -0.5vw;
    width: 3vw;
    height: 3vw;
    opacity: 1;
  }
  .close:hover {
    cursor: pointer;
    opacity: 0.3;
  }
  .close:before,
  .close:after {
    position: absolute;
    left: calc(45%);
    top: calc(25%);
    content: " ";
    height: calc(50%);
    width: calc(12.5%);
    background-color: white;
    z-index: 15;
  }

  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  .modal {
    display: grid;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    background-color: white;
    padding: 2rem 3rem;
    text-align: left;
    width: 60vw;
    height: 90vh;
    z-index: 10;
    position: fixed;
    top: 5vh;
    left: 20vw;
    grid-template-rows: 70% 30%;
  }

  .modalNoGrid {
    display: flex;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    background-color: white;
    padding: 2rem 5rem;
    text-align: justify;
    width: 60vw;
    height: 90vh;
    z-index: 10;
    position: fixed;
    top: 5vh;
    left: 20vw;
  }

  .description {
    overflow-y: scroll;
    text-align: justify;
    height: 90%;
    padding: 0 25px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0px;
  }

  .link {
    color: #055f80;
    font-weight: bold;
    text-decoration: none;
  }

  .curriculum {
    overflow-y: scroll;
  }

  .title {
    color: #055f80;
    font-size: 1.5rem;
    font-weight: bold;
  }
}
/* Versión Mobile */
@media only screen and (max-width: 768px) {
  .buttonBackground {
    position: absolute;
    right: -8px;
    top: -8px;
    background-color: #055f80;
    width: 10vw;
    height: 10vw;
    border-radius: 100%;
  }

  .close {
    position: absolute;
    right: -2.25vw;
    top: -1.5vw;
    width: 10vw;
    height: 10vw;
    opacity: 1;
  }
  .close:hover {
    cursor: pointer;
    opacity: 0.3;
  }
  .close:before,
  .close:after {
    position: absolute;
    left: calc(45%);
    top: calc(25%);
    content: " ";
    height: calc(50%);
    width: calc(12.5%);
    background-color: white;
    z-index: 15;
  }

  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  .modal {
    display: grid;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    background-color: white;
    padding: 0.5rem 1rem;
    text-align: left;
    width: 90vw;
    height: 60vh;
    z-index: 10;
    position: fixed;
    top: 20vh;
    left: 5vw;
    grid-template-rows: 60% 40%;
    justify-content: center;
  }

  .modalNoGrid {
    display: flex;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    background-color: white;
    padding: 0.5rem 1rem;
    text-align: justify;
    width: 90vw;
    height: 70vh;
    z-index: 10;
    position: fixed;
    top: 15vh;
    left: 5vw;
  }

  .description {
    overflow-y: scroll;
    text-align: justify;
    height: 90%;
    padding: 0 25px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0px;
  }

  .link {
    color: #055f80;
    font-weight: bold;
    text-decoration: none;
  }

  .curriculum {
    overflow-y: scroll;
  }

  .title {
    color: #055f80;
    font-size: 1.5rem;
    font-weight: bold;
  }
}
