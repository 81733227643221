/* Versión Escritorio */
@media only screen and (min-width: 768px) {
  .infoContainer {
    box-shadow: 5px 10px 4px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    background-color: white;
    padding: 1rem;
    height: 15%;
    width: 22.5%;
    z-index: 2;
    position: fixed;
    top: calc(70%);
    min-width: 0px;

    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 20% 80%;
    overflow: hidden;
  }

  .left {
    left: calc(12.5%);
  }

  .center {
    left: calc(39%);
  }

  .right {
    right: calc(12.5%);
  }

  .imageDiv {
    display: flex;
    align-items: center;
    justify-content: space-around;
    grid-column: 1/2;
    grid-row: 1/3;
  }

  .image {
    width: 70%;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: left;
    font-weight: 600;
    grid-column: 2/3;
    grid-row: 1/2;
    padding-left: 0.5rem;
  }

  .description {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    font-size: 0.9rem;
    grid-column: 2/3;
    grid-row: 2/3;
    padding-left: 0.5rem;
  }
}

/* Versión Mobile */
@media only screen and (max-width: 768px) {
  .infoContainer {
    box-shadow: 5px 10px 4px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    background-color: white;
    padding: 0.5rem;
    height: 12.5vh;
    width: 45vw;
    z-index: 2;
    position: absolute;
    min-width: 0px;

    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 10% 90%;
    overflow: hidden;
  }

  .left {
    right: 0;
  }

  .center {
    right: 0;
  }

  .right {
    right: 0;
  }

  .imageDiv {
    display: flex;
    align-items: center;
    justify-content: space-around;
    grid-column: 1/2;
    grid-row: 1/3;
  }

  .image {
    width: 100%;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 0.75rem;
    font-weight: 600;
    grid-column: 2/3;
    grid-row: 1/2;
    padding-left: 0.5rem;
  }

  .description {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    font-size: 0.7rem;
    grid-column: 2/3;
    grid-row: 2/3;
    padding-left: 0.5rem;
  }
}
