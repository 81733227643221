* {
  box-sizing: border-box;
}

html,body {
  height: 100%;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
   url("./fonts/Raleway/static/Raleway-Regular.ttf") format("truetype");
  }

body {
  font-family: "Raleway";
  /* font-family: 'Open Sans', sans-serif; */
  margin: 0;
  background-color: #f2f2f2;
}

