/* Versión Escritorio */
@media only screen and (min-width: 768px) {

  .buttonBackground {
    position: absolute;
    right: -8px;
    top: -8px;
    background-color: #055f80;
    width: calc(5%);
    height: calc(8.88%);
    border-radius: 100%;
  }
  .close {
    position: absolute;
    right: -8px;
    top: -8px;
    width: calc(5%);
    height: calc(8.88%);
    opacity: 1;
  }
  .close:hover {
    cursor: pointer;
    opacity: 0.3;
  }
  .close:before,
  .close:after {
    position: absolute;
    left: calc(45%);
    top: calc(25%);
    content: " ";
    height: calc(50%);
    width: calc(12.5%);
    background-color: white;
    z-index: 15;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  .modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    background-color: white;
    padding: 2rem 3rem;
    text-align: left;
    width: 60%;
    z-index: 10;
    position: fixed;
    top: 20vh;
    left: calc(20%);
  }

  .title {
    text-align: center;
    color: #6b6b6b;
  }

  .text_title {
    font-size: 2rem !important;
    font-weight: 900 !important;
    margin-block-start: 1rem !important;
    margin-block-end: 0.2rem !important;
  }

  .title p {
    font-size: 0.75rem;
    margin-block-start: 0.2rem;
    margin-block-end: 2rem;
  }

  .title hr {
    margin-top: 2px;
    margin-bottom: 2px;
    width: calc(50%);
  }

  .form {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .form hr {
    border: 0.5px dashed;
    width: 100%;
    color: white;
  }

  .control {
    margin-bottom: 0.5rem;
    padding-left: calc(20%);
    padding-right: calc(20%);
  }

  .control label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .control input,
  .control textarea {
    display: block;
    font: inherit;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 0.25rem;
    width: 100%;
    resize: none;
  }

  .actions {
    margin-top: 1rem;
    text-align: left;
    padding-left: calc(20%);
  }

  .actions button {
    font: inherit;
    cursor: pointer;
    background-color: #77002e;
    color: white;
    padding: 0.5rem 1.5rem;
    border: 1px solid #77002e;
    border-radius: 4px;
    font-weight: bold;
  }

  .actions button:hover,
  .actions button:active {
    background-color: #a50e48;
    border-color: #a50e48;
  }
}

/* Versión Mobile */
@media only screen and (max-width: 768px) {
  .buttonBackground {
    position: absolute;
    right: -8px;
    top: -8px;
    background-color: #055f80;
    width: 10vw;
    height: calc(8.88%);
    border-radius: 100%;
  }
  .close {
    position: absolute;
    right: 2px;
    top: -8px;
    width: calc(5%);
    height: calc(8.88%);
    opacity: 1;
  }
  .close:hover {
    cursor: pointer;
    opacity: 0.3;
  }
  .close:before,
  .close:after {
    position: absolute;
    left: calc(45%);
    top: calc(25%);
    content: " ";
    height: calc(50%);
    width: calc(12.5%);
    background-color: white;
    z-index: 15;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  .modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    background-color: white;
    padding: 1rem 1rem;
    text-align: left;
    width: 90vw;
    z-index: 10;
    position: fixed;
    top: 20vh;
    left: 5vw;
  }

  .title {
    text-align: center;
    color: #6b6b6b;
  }

  .text_title {
    font-size: 2rem !important;
    font-weight: 900 !important;
    margin-block-start: 0.25rem !important;
    margin-block-end: 0.1rem !important;
  }

  .title p {
    font-size: 0.75rem;
    margin-block-start: 0.2rem;
    margin-block-end: 1rem;
  }

  .title hr {
    margin-top: 2px;
    margin-bottom: 2px;
    width: calc(50%);
  }

  .form {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .form hr {
    border: 0.5px dashed;
    width: 100%;
    color: white;
  }

  .control {
    margin-bottom: 0.5rem;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .control label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .control input,
  .control textarea {
    display: block;
    font: inherit;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 0.25rem;
    width: 100%;
    resize: none;
  }

  .actions {
    margin-top: 1rem;
    text-align: left;
    padding-left: calc(20%);
  }

  .actions button {
    font: inherit;
    cursor: pointer;
    background-color: #77002e;
    color: white;
    padding: 0.5rem 1.5rem;
    border: 1px solid #77002e;
    border-radius: 4px;
    font-weight: bold;
  }

  .actions button:hover,
  .actions button:active {
    background-color: #a50e48;
    border-color: #a50e48;
  }
}
