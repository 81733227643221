/* Versión Escritorio */
@media only screen and (min-width: 768px) {
  .mainContainer {
    height: 100%;
    width: 100%;
  }

  .carouselContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    height: 97.5%;
    width: 100%;
  }
  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 100%;
    background-color: #d8d8d6;
    border-radius: 1vw;
  }

  .image {
    max-width: 70%;
    max-height: 95%;
  }

  .leftArrow {
    position: absolute;
    font-size: 32px;
    left: 6rem;
    font-size: 32px;
    font-weight: bolder;
    color: aliceblue;
    transform: rotate(180deg);
    cursor: pointer;
  }

  .rightArrow {
    position: absolute;
    font-size: 32px;
    right: 6rem;
    font-weight: bolder;
    color: aliceblue;
    cursor: pointer;
  }

  .leftArrow:hover {
    cursor: pointer;
    opacity: 0.3;
  }

  .rightArrow:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  .dotContainer {
    position: relative;
    bottom: 2rem;
    display: flex;
    height: 5%;
    justify-content: center;
  }

  .dot {
    margin: 0 1rem;
    cursor: pointer;
    font-size: 1rem;
  }

  .zoomMainContainer {
    height: 100vh;
    width: 90vw;
    position: fixed;
    top: 0;
    left: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 6;
  }

  .zoomContainer {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    max-height: 90vh;
    max-width: 90vw;
    z-index: 20;
  }

  .zoomImage {
    position: fixed;
    max-width: 90%;
    max-height: 95%;
    z-index: 30;
  }

  .leftArrowZoom {
    position: fixed;
    font-size: 32px;
    top: 50vh;
    left: 1rem;
    font-size: 32px;
    font-weight: bolder;
    color: aliceblue;
    transform: rotate(180deg);
    cursor: pointer;
    z-index: 50;
  }

  .rightArrowZoom {
    position: fixed;
    font-size: 32px;
    top: 50vh;
    right: 1rem;
    font-weight: bolder;
    color: aliceblue;
    cursor: pointer;
    z-index: 50;
  }
}

/* Versión Mobile */
@media only screen and (max-width: 768px) {
  .mainContainer {
    height: 100%;
    width: 80vw;
  }

  .carouselContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    height: 97.5%;
    width: 80vw;
  }
  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    height: 100%;
    background-color: #d8d8d6;
    border-radius: 1vw;
  }

  .image {
    max-width: 70%;
    max-height: 95%;
  }

  .leftArrow {
    position: absolute;
    font-size: 32px;
    left: 6vw;
    font-size: 32px;
    font-weight: bolder;
    color: aliceblue;
    transform: rotate(180deg);
    cursor: pointer;
  }

  .rightArrow {
    position: absolute;
    font-size: 32px;
    right: 6vw;
    font-weight: bolder;
    color: aliceblue;
    cursor: pointer;
  }

  .leftArrow:hover {
    cursor: pointer;
    opacity: 0.3;
  }

  .rightArrow:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  .dotContainer {
    position: relative;
    bottom: 2rem;
    display: flex;
    height: 5%;
    justify-content: center;
  }

  .dot {
    margin: 0 0.5rem;
    cursor: pointer;
    font-size: 1rem;
  }

  .zoomMainContainer {
    height: 100vh;
    width: 90vw;
    position: fixed;
    top: 0;
    left: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 6;
  }

  .zoomContainer {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    max-height: 90vh;
    max-width: 90vw;
    z-index: 20;
  }

  .zoomImage {
    position: fixed;
    max-width: 90%;
    max-height: 95%;
    z-index: 30;
  }

  .leftArrowZoom {
    position: fixed;
    font-size: 32px;
    top: 50vh;
    left: 1rem;
    font-size: 32px;
    font-weight: bolder;
    color: aliceblue;
    transform: rotate(180deg);
    cursor: pointer;
    z-index: 50;
  }

  .rightArrowZoom {
    position: fixed;
    font-size: 32px;
    top: 50vh;
    right: 1rem;
    font-weight: bolder;
    color: aliceblue;
    cursor: pointer;
    z-index: 50;
  }
}
