/* Versión escritorio */
@media only screen and (min-width: 768px) {
  :root {
    --border: #e3e3e2;
    --backgound: #d8d8d6;
    /*FirstShelf*/
    --book1Factor: 20%;
    --book2Factor: 40%;
    --book3Factor: 20%;
    /*SecondShelf*/
    --book4Factor: 20%;
    --book5Factor: 35%;
    --book6Factor: 20%;
    /*ThirdShelf*/
    --book7Factor: 22.5%;
    --book8Factor: 22.5%;
    --book9Factor: 27.5%;
    --book10Factor: 22.5%;
    /*FourthShelf*/
    --book11Factor: 35%;
    --book12Factor: 27.5%;
    --book13Factor: 35%;
  }
  .mainContainer {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }

  .pageLayout {
    display: flex;
    min-width: 100%;
    min-height: 100%;
  }

  .shelf {
    display: grid;
    width: 90%;
    height: 95%;
    background-color: var(--backgound);
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      "firstShelf secondShelf"
      "thirdShelf fourthShelf";
  }

  .firstShelf {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    grid-column: 1/2;
    grid-row: 1/2;
    border: 1vw solid var(--border);
  }

  .secondShelf {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    grid-column: 2/3;
    grid-row: 1/2;
    border: 1vw solid var(--border);
  }

  .auxShelf {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 85% 15%;
    align-items: end;
    height: 100%;
    grid-column: 1/3;
    grid-row: 2/3;
    border: 1vw solid var(--border);
  }

  .thirdShelf {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    grid-column: 1/2;
    grid-row: 1/2;
    justify-content: flex-end;
  }

  .fourthShelf {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    grid-column: 2/3;
    grid-row: 1/2;
    justify-content: flex-end;
  }

  .shelfDescription {
    background-color: #d1b38f;
    text-align: center;
    font-weight: bolder;
    font-size: 100%;
    width: 100%;
    height: 2rem;
    line-height: 2rem;
    text-shadow: 1px 0 #000000;
  }

  .bottomDescription {
    background-color: #d1b38f;
    text-align: center;
    font-weight: bolder;
    font-size: 100%;
    width: 100%;
    height: 100%;
    line-height: 2rem;
    text-shadow: 1px 0 #000000;
    grid-column: 1/3;
    grid-row: 2/3;
  }

  .book1 {
    /* position: relative; */
    /* transform: var(--size); */
    max-width: 100%;
    max-height: var(--book1Factor);
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book2 {
    /* position: absolute; */
    max-width: 100%;
    max-height: var(--book2Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book3 {
    /* position: absolute; */
    max-width: 100%;
    max-height: var(--book3Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book4 {
    /* position: absolute; */
    max-width: 100%;
    max-height: var(--book4Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book5 {
    /* position: absolute; */
    max-width: 100%;
    max-height: var(--book5Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book6 {
    /* position: absolute; */
    max-width: 100%;
    max-height: var(--book6Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book7 {
    /* position: absolute; */
    max-width: 100%;
    max-height: var(--book7Factor);
    height: auto;
    top: 0;
    left: 0;
  }

  .book8 {
    /*  position: absolute; */
    max-width: 100%;
    max-height: var(--book8Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book9 {
    /* position: absolute; */
    max-width: 100%;
    max-height: var(--book9Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book10 {
    /* position: absolute; */
    max-width: 100%;
    max-height: var(--book10Factor);
    height: auto;
    top: 0;
    left: 0;
  }

  .book11 {
    /*  position: absolute; */
    max-width: 100%;
    max-height: var(--book11Factor);
    height: auto;
    top: 0;
    left: 0;
  }

  .book12 {
    /* position: absolute; */
    max-width: 100%;
    max-height: var(--book12Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book13 {
    /* position: absolute; */
    max-width: 100%;
    max-height: var(--book13Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}

/* Versión Mobile */
@media only screen and (max-width: 768px) {
  :root {
    --border: #e3e3e2;
    --backgound: #d8d8d6;
    /*FirstShelf*/
    --book1Factor: 80%;
    --book2Factor: 65%;
    --book3Factor: 20%;
    /*SecondShelf*/
    --book4Factor: 70%;
    --book5Factor: 80%;
    --book6Factor: 90%;
    /*ThirdShelf*/
    --book7Factor: 60%;
    --book8Factor: 90%;
    --book9Factor: 80%;
    --book10Factor: 70%;
    /*FourthShelf*/
    --book11Factor: 90%;
    --book12Factor: 80%;
    --book13Factor: 70%;
  }
  .mainContainer {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }

  .pageLayout {
    display: flex;
    min-width: 100%;
    min-height: 100%;
  }

  .shelf {
    display: grid;
    width: 90%;
    height: 100%;
    background-color: var(--backgound);
    grid-template-rows: min-content min-content min-content;
  }

  .firstShelf {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    grid-row: 1/2;
    border: 1vw solid var(--border);
  }

  .secondShelf {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    grid-row: 2/3;
    border: 1vw solid var(--border);
  }

  .auxShelf {
    display: grid;
    align-items: center;
    grid-row: 3/4;

    grid-template-rows: min-content min-content auto;
    border: 1vw solid var(--border);
    flex-direction: column;
    justify-content: flex-end;
  }

  .thirdShelf {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row: 1/2;
    justify-content: flex-end;
    background-color: var(--backgound);
  }

  .fourthShelf {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row: 2/3;
    justify-content: flex-end;
    background-color: var(--backgound);
    
  }

  .shelfDescription {
    background-color: #d1b38f;
    text-align: center;
    font-weight: bolder;
    font-size: 100%;
    width: 100%;
    height: 2rem;
    line-height: 2rem;
    text-shadow: 1px 0 #000000;
  }

  .bottomDescription {
    background-color: #d1b38f;
    text-align: center;
    font-weight: bolder;
    font-size: 100%;
    width: 100%;
    height: 100%;
    line-height: 2rem;
    text-shadow: 1px 0 #000000;
  }

  .book1 {
    /* position: relative; */
    /* transform: var(--size); */
    max-width: var(--book1Factor);
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book2 {
    /* position: absolute; */
    max-width: var(--book2Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book3 {
    /* position: absolute; */
    max-width: var(--book3Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book4 {
    /* position: absolute; */
    max-width: var(--book4Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book5 {
    /* position: absolute; */
    max-width: var(--book5Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book6 {
    /* position: absolute; */
    max-width: var(--book6Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book7 {
    /* position: absolute; */
    max-width: var(--book7Factor);
    height: auto;
    top: 0;
    left: 0;
  }

  .book8 {
    /*  position: absolute; */
    max-width: var(--book8Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book9 {
    /* position: absolute; */
    max-width: var(--book9Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book10 {
    /* position: absolute; */
    max-width: var(--book10Factor);
    height: auto;
    top: 0;
    left: 0;
  }

  .book11 {
    /*  position: absolute; */
    max-width: var(--book11Factor);
    height: auto;
    top: 0;
    left: 0;
  }

  .book12 {
    /* position: absolute; */
    max-width: var(--book12Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .book13 {
    /* position: absolute; */
    max-width: var(--book13Factor);
    height: auto;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .modalContainer{
    display: flex;
  }
}
